<template>
	<div>
		<div class="box">
		    <CustomTable
		        id_table="tiers_suppliers_invoices_details"
		        :items="invoicesDetailsFormatted"
		        :busy.sync="table_busy"
		        primaryKey="invoicesuppliersdetails_id"
		        :hide_if_empty="true"
				:hrefsRoutes="config_table_hrefs"
		    >
		    </CustomTable>
		</div>
	</div>
</template>


<script type="text/javascript">
	import Common from '@/assets/js/common'
    import Navigation from "@/mixins/Navigation.js";
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import Tiers from "@/mixins/Tiers.js"
	import Article from "@/mixins/Article.js"
	import Horse from "@/mixins/Horse.js"

	export default {
		name: "InvoiceSupplierDetails",
		mixins: [Invoice, Shutter, Tiers, Article, Horse, Navigation],
		props: {
			invoice: Object
		},
		data () {
			return {
				table_busy: true,
				invoices_suppliers_details: [],
				events_tab: {
					'TableAction::goToAddSupplierInvoiceDetails': () => {
						this.ok({invoice: this.invoice})
					},
					'TableAction::goToEditSupplierInvoiceDetails': (params) => {
						let object_custom = {
							invoice: this.invoice,
							invoice_details: params
						}
						this.ok(object_custom)
					}
				},
				config_table_hrefs: {
				'horse.horse_nom': {
					routeUniqueName: 'horseFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse.horse_id'
					}
				},
				'tier.tiers_label': {
					routeUniqueName: 'tiersFiche',
					routeName: 'tiersFiche',
					params: {
						tiers_id: 'tier.tiers_id'
					}
				}

			},
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			async preinit_component() {
				this.invoices_suppliers_details = await this.getInvoiceSuppliersDetailsList(this.invoice.invoice_id)
                this.table_busy = false

			},
			
			
		},
		computed: {
			invoicesDetailsFormatted(){
				let tab = []

				for (let i = 0; i < this.invoices_suppliers_details.length; i++) {
				    let temp = this.invoices_suppliers_details[i]
					temp.tier.tiers_label = temp.tier.tiers_rs ? temp.tier.tiers_rs : temp.tier.tiers_name + ' ' + temp.tier.tiers_firstname
				    tab.push(temp)
				}

				return tab
			}
		},
		watch: {
			
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>